import React, { useEffect, useState } from 'react';
import { Navigate } from 'react-router-dom';
import { doc, getDoc } from 'firebase/firestore';
import { db, auth } from '../firebase';
import WalletPage from '../pages/WalletPage';
import Spinner from '../@/components/ui/spinner';

const WalletPageWrapper: React.FC = () => {
  const [isLoading, setIsLoading] = useState(true);
  const [onboardingCompleted, setOnboardingCompleted] = useState<boolean | null>(null);

  useEffect(() => {
    const checkOnboarding = async () => {
      const user = auth.currentUser;
      if (user) {
        const storedOnboardingStatus = localStorage.getItem('onboardingCompleted');
        if (storedOnboardingStatus !== null) {
          setOnboardingCompleted(storedOnboardingStatus === 'true');
          setIsLoading(false);
          return;
        }
  
        const userDocRef = doc(db, "users", user.uid);
        const userDocSnap = await getDoc(userDocRef);
        if (userDocSnap.exists()) {
          const userData = userDocSnap.data();
          const isCompleted = userData.onboardingCompleted || false;
          setOnboardingCompleted(isCompleted);
          localStorage.setItem('onboardingCompleted', isCompleted.toString());
        } else {
          // Ajouter un délai avant de rediriger vers l'onboarding
          setTimeout(() => {
            setOnboardingCompleted(false);
            localStorage.setItem('onboardingCompleted', 'false');
          }, 1000);
        }
      }
      setIsLoading(false);
    };
  
    checkOnboarding();
  }, []);

  if (isLoading) {
    return <Spinner />;
  }

  if (onboardingCompleted === false) {
    return <Navigate to="/onboarding" />;
  }

  return <WalletPage />;
};

export default WalletPageWrapper;