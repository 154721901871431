import React, { useState, useEffect, useMemo } from "react";
import { MapContainer, TileLayer, Marker, Popup, useMap } from "react-leaflet";
import "leaflet/dist/leaflet.css";
import L from "leaflet";
import { Shop } from "../App";
import { Button } from "../@/components/ui/button";
import { X, Search } from "lucide-react";
import { useNavigate } from "react-router-dom";
import { Geocoder } from "../utils/geocoder";
import { Input } from "../@/components/ui/input";
import { Capacitor } from "@capacitor/core";
import { Geolocation } from "@capacitor/geolocation";
import iconMarker from "../assets/iconmarker.png";
import useMapSize from "../hooks/useMapSize";
import "./MapPage.css";
import Spinner from "src/@/components/ui/spinner";

// Suppression des icônes par défaut de Leaflet
delete (L.Icon.Default.prototype as any)._getIconUrl;
L.Icon.Default.mergeOptions({
  iconRetinaUrl: require("leaflet/dist/images/marker-icon-2x.png"),
  iconUrl: require("leaflet/dist/images/marker-icon.png"),
  shadowUrl: require("leaflet/dist/images/marker-shadow.png"),
});

interface MapPageProps {
  shops: Shop[];
}

interface GeocodeCache {
  [address: string]: { lat: string; lng: string };
}

const CACHE_KEY = 'shopsCacheData';
const CACHE_TIMESTAMP_KEY = 'shopsCacheTimestamp';
const ONE_DAY_IN_MS = 24 * 60 * 60 * 1000;

const ChangeView = ({ center, zoom }: { center: [number, number]; zoom: number }) => {
  const map = useMap();
  map.setView(center, zoom);
  return null;
};

const MapPage: React.FC<MapPageProps> = ({ shops }) => {
  const navigate = useNavigate();
  const [searchTerm, setSearchTerm] = useState("");
  const [filteredShops, setFilteredShops] = useState<Shop[]>([]);
  const [userLocation, setUserLocation] = useState<{
    latitude: number;
    longitude: number;
  } | null>(null);
  const [error, setError] = useState<string | null>(null);
  const isNative = Capacitor.isNativePlatform();
  const mapSize = useMapSize();
  const isIOS = Capacitor.getPlatform() === "ios";
  const [cachedShops, setCachedShops] = useState<Shop[]>([]);
  const [isLoading, setIsLoading] = useState(true);
  const geocodeCache = useMemo<GeocodeCache>(() => ({}), []);

  const defaultCenter: [number, number] = [46.3167, 7.7167];
  const defaultZoom = 8;

  const getUserLocation = async () => {
    try {
      let newLocation;
      if (isNative) {
        const coordinates = await Geolocation.getCurrentPosition({
          enableHighAccuracy: true,
          timeout: 5000,
        });
        newLocation = {
          latitude: coordinates.coords.latitude,
          longitude: coordinates.coords.longitude,
        };
      } else {
        if (navigator.geolocation) {
          const position = await new Promise<GeolocationPosition>((resolve, reject) =>
            navigator.geolocation.getCurrentPosition(resolve, reject, {
              enableHighAccuracy: true,
              timeout: 5000,
              maximumAge: 0,
            })
          );
          newLocation = {
            latitude: position.coords.latitude,
            longitude: position.coords.longitude,
          };
        } else {
          setError("La géolocalisation n'est pas supportée par ce navigateur.");
          return;
        }
      }

      setUserLocation(newLocation);
    } catch (error) {
      console.error("Erreur lors de la récupération de la position de l'utilisateur:", error);
      setError("Une erreur s'est produite lors de la récupération de la position.");
    }
  };

  useEffect(() => {
    const loadShopsData = async () => {
      setIsLoading(true);
      
      const cachedData = localStorage.getItem(CACHE_KEY);
      const cachedTimestamp = localStorage.getItem(CACHE_TIMESTAMP_KEY);
      const now = new Date().getTime();

      if (cachedData && cachedTimestamp && (now - parseInt(cachedTimestamp) < ONE_DAY_IN_MS)) {
        setCachedShops(JSON.parse(cachedData));
      } else {
        const geocodedShops = await geocodeShops(shops);
        setCachedShops(geocodedShops);
        localStorage.setItem(CACHE_KEY, JSON.stringify(geocodedShops));
        localStorage.setItem(CACHE_TIMESTAMP_KEY, now.toString());
      }
      setIsLoading(false);
    };

    loadShopsData();
    getUserLocation();
  }, [shops]);

  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      const filtered = cachedShops.filter(
        (shop) =>
          shop.name.toLowerCase().includes(searchTerm.toLowerCase()) ||
          shop.city.toLowerCase().includes(searchTerm.toLowerCase())
      );
      setFilteredShops(filtered);
    }, 300);
  
    return () => clearTimeout(delayDebounceFn);
  }, [searchTerm, cachedShops]);

  const geocodeShops = async (shopsToGeocode: Shop[]) => {
    const geocoder = new Geocoder();
    const geoCodedShops = await Promise.all(
      shopsToGeocode.map(async (shop) => {
        if (!shop.latitude || !shop.longitude) {
          const address = `${shop.address}, ${shop.postalCode} ${shop.city}, ${shop.country}`;
          
          if (geocodeCache[address]) {
            return {
              ...shop,
              latitude: geocodeCache[address].lat,
              longitude: geocodeCache[address].lng,
            };
          }
  
          const result = await geocoder.geocode(address);
          if (result) {
            geocodeCache[address] = { lat: result.lat.toString(), lng: result.lng.toString() };
            return {
              ...shop,
              latitude: result.lat.toString(),
              longitude: result.lng.toString(),
            };
          }
        }
        return shop;
      })
    );
    return geoCodedShops.filter((shop) => shop.latitude && shop.longitude);
  };

  const openInMaps = (shop: Shop) => {
    const address = `${shop.address}, ${shop.postalCode} ${shop.city}, ${shop.country}`;
    const encodedAddress = encodeURIComponent(address);

    const isAppleDevice = /iPhone|iPad|iPod|Macintosh/.test(navigator.userAgent);

    if (isAppleDevice) {
      const appleMapsUrl = `maps:0,0?q=${encodedAddress}`;
      window.location.href = appleMapsUrl;
    } else {
      const googleMapsUrl = `https://www.google.com/maps/search/?api=1&query=${encodedAddress}`;
      window.open(googleMapsUrl, "_blank");
    }
  };

  const customIcon = new L.Icon({
    iconUrl: iconMarker,
    iconSize: [30, 30],
    iconAnchor: [15, 30],
    popupAnchor: [0, -30],
  });

  return (
    <div className={`map-page ${isIOS ? "ios-device" : ""}`}>
      <div className="p-4 bg-white shadow-md z-10 flex justify-between items-center relative">
        <div className="relative flex-grow">
          <Input
            type="text"
            placeholder="Rechercher un magasin ou une ville"
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
            className="w-full pl-2 pr-10 py-1 h-12"
          />
          <Button
            variant="ghost"
            size="icon"
            className="absolute right-0 top-1/2 transform -translate-y-1/2 h-12 w-12 rounded-full"
            onClick={() => {/* Fonctionnalité de recherche */}}
          >
            <Search className="h-6 w-6" />
          </Button>
        </div>
        <Button
          variant="ghost"
          size="icon"
          className="rounded-full w-12 h-12 ml-2"
          onClick={() => navigate(-1)}
        >
          <X className="h-6 w-6" />
        </Button>
      </div>
      {isLoading || !userLocation ? (
      <div className="flex items-center justify-center h-full">
        <Spinner />
      </div>
      ) : (
        <div className="flex-1 relative overflow-hidden">
          <MapContainer center={defaultCenter} zoom={defaultZoom} zoomControl={false}>
            <ChangeView 
              center={[userLocation.latitude, userLocation.longitude]} 
              zoom={13} 
            />
            <TileLayer
              url="https://{s}.basemaps.cartocdn.com/light_all/{z}/{x}/{y}{r}.png"
              attribution='&copy; <a href="https://carto.com/attributions">CARTO</a>'
            />
            {(searchTerm ? filteredShops : cachedShops).map((shop) => (
              <Marker
                key={shop.id}
                position={[
                  parseFloat(shop.latitude || "0"),
                  parseFloat(shop.longitude || "0"),
                ]}
                icon={customIcon}
              >
                <Popup>
                  <div
                    onClick={() => openInMaps(shop)}
                    className="cursor-pointer"
                  >
                    <strong>{shop.name}</strong>
                    <br />
                    {shop.address}
                    <br />
                    {shop.city}, {shop.postalCode}
                  </div>
                </Popup>
              </Marker>
            ))}
          </MapContainer>
          {error && (
            <p className="absolute bottom-0 left-0 p-4 bg-red-200 text-red-800">
              {error}
            </p>
          )}
        </div>
      )}
    </div>
  );
};

export default MapPage;