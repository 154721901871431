import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Button } from "../@/components/ui/button";
import {
  Card,
  CardHeader,
  CardTitle,
  CardContent,
} from "../@/components/ui/card";
import { doc, updateDoc, getDoc } from "firebase/firestore";
import { db, auth } from "../firebase";
import {
  ChevronRight,
  CreditCard,
  QrCode,
  Gift,
  Store,
  BarChart2,
  User,
  Earth,
} from "lucide-react";
import { getFunctions, httpsCallable } from "firebase/functions";
import { app } from "../firebase";
import { Checkbox } from "../@/components/ui/custom-checkbox";
import { Label } from "../@/components/ui/label";
import { Capacitor } from "@capacitor/core";
import { Browser } from "@capacitor/browser";

const Onboarding: React.FC = () => {
  const [step, setStep] = useState(0);
  const [userType, setUserType] = useState<"client" | "merchant" | null>(null);
  const navigate = useNavigate();
  const [onboardingCompleted, setOnboardingCompleted] = useState(false);
  const [marketingConsent, setMarketingConsent] = useState(false);
  const [legalConsent, setLegalConsent] = useState(false);
  const [termsAccepted, setTermsAccepted] = useState(false);
  const [userEmail, setUserEmail] = useState<string | null>(null);

  useEffect(() => {
    const checkOnboarding = async (userId: string) => {
      const userDocRef = doc(db, "users", userId);
      const userDocSnap = await getDoc(userDocRef);
      if (userDocSnap.exists()) {
        const userData = userDocSnap.data();
        if (userData.onboardingCompleted) {
          navigate("/");
        } else {
          // Vérifier si shops existe, sinon l'initialiser
          if (!userData.shops) {
            await updateDoc(userDocRef, { shops: [] });
          }
        }
      }
    };

    const user = auth.currentUser;
    if (user) {
      checkOnboarding(user.uid);
    } else {
      navigate("/login");
    }
  }, [navigate]);

  const clientSteps = [
    {
      title: "Bienvenue dans votre portefeuille digital !",
      content:
        "FideliPass révolutionne vos cartes de fidélité. Fini les cartes papier, place à la simplicité du numérique !",
    },
    {
      title: "Un QR code, une multitude d'avantages",
      content:
        "Présentez votre QR code unique lors de vos achats. Accumulez des points et des récompenses en un clin d'œil, sans effort.",
    },
    {
      title: "Suivez vos récompenses en temps réel",
      content:
        "Visualisez instantanément vos points et récompenses pour chaque commerce.",
    },
    {
      title: "Explorez un monde de fidélité",
      content:
        "Découvrez notre réseau grandissant de commerces partenaires. Nouvelles enseignes, nouvelles récompenses : l'aventure FideliPass commence !",
    },
  ];

  const merchantSteps = [
    {
      title: "Votre programme de fidélité 2.0",
      content:
        "Bienvenue sur FideliPass ! Prêt à transformer votre relation client ?",
    },
    {
      title: "Créez votre profil commerçant",
      content:
        "Renseignez les informations de votre commerce et personnalisez votre programme de fidélité.",
    },
    {
      title: "Scannez les QR codes",
      content:
        "Utilisez l'application pour scanner les QR codes de vos clients et attribuer des points.",
    },
    {
      title: "Vos données, votre succès",
      content:
        "Accédez à des statistiques détaillées sur votre programme de fidélité et vos clients.",
    },
    {
      title: "Brillez dans notre écosystème digital",
      content:
        "Gagnez en visibilité ! Votre commerce s'affiche sur notre carte interactive et se démarque dans notre répertoire de partenaires. Grâce à notre fonction de recherche intuitive, les clients vous trouvent facilement. Attirez une nouvelle clientèle déjà conquise par FideliPass et devenez leur prochaine destination favorite !",
    },
  ];

  const steps = userType === "client" ? clientSteps : merchantSteps;

  const handleFinishOnboarding = async () => {
    const user = auth.currentUser;
    if (user) {
      if (!termsAccepted) {
        alert(
          "Vous devez accepter les termes d'utilisation pour continuer."
        );
        return;
      }
      try {
        const functions = getFunctions(app, "europe-west6");
        const handleConsents = httpsCallable(functions, "handleEmailConsents");

        if (legalConsent || marketingConsent) {
          await handleConsents({
            userId: user.uid,
            legalConsent,
            marketingConsent,
          });
        }

        await updateDoc(doc(db, "users", user.uid), {
          onboardingCompleted: true,
        });
        localStorage.setItem("onboardingCompleted", "true");
        if (userType === "merchant") {
          navigate("/payment");
        } else {
          navigate("/");
        }
      } catch (error) {
        console.error("Erreur lors de la mise à jour de l'onboarding:", error);
        alert(
          "Une erreur est survenue lors de la finalisation de l'onboarding. Veuillez réessayer."
        );
      }
    } else {
      console.error("Aucun utilisateur connecté");
      navigate("/login");
    }
  };

  const handleNext = () => {
    if (step < steps.length) {
      setStep(step + 1);
    } else {
      handleFinishOnboarding();
    }
  };

  const handleDeleteAccount = async () => {
    const confirmDelete = window.confirm(
      "Êtes-vous sûr de vouloir supprimer votre compte ? Cette action est irréversible."
    );
    if (!confirmDelete) return;
    const user = auth.currentUser;
    if (user) {
      try {
        const functions = getFunctions(app, "europe-west6");
        const deleteUserAccountFunction = httpsCallable<
          { userCountry: string },
          { success: boolean; message: string }
        >(functions, "deleteUserAccount");

        const result = await deleteUserAccountFunction({ userCountry: "" });

        if (result.data.success) {
          localStorage.clear();
          window.location.reload();
          navigate("/login");
        } else {
          throw new Error(result.data.message);
        }
      } catch (error) {
        console.error("Erreur lors de la suppression du compte:", error);
        if (error instanceof Error) {
          alert(
            `Une erreur est survenue lors de la suppression du compte : ${error.message}`
          );
        } else {
          alert(
            "Une erreur inconnue est survenue lors de la suppression du compte. Veuillez réessayer."
          );
        }
      }
    }
  };

  const openLink = (url: string) => {
    if (Capacitor.isNativePlatform()) {
      Browser.open({ url });
    } else {
      window.open(url, "_blank");
    }
  };

  useEffect(() => {
    const user = auth.currentUser;
    if (user) {
      setUserEmail(user.email);
    }
  }, []);

  return (
    <div className="fixed inset-0 flex items-center justify-center bg-background p-4 overflow-hidden">
      <div className="w-full max-w-md">
        {step === 0 ? (
          <Card className="w-full max-w-md shadow-sm">
            <CardHeader>
              <CardTitle className="text-2xl font-bold text-center">
                Bienvenue sur FideliPass
              </CardTitle>
            </CardHeader>
            <CardContent className="space-y-6">
              <p className="text-center text-muted-foreground mb-4">
                Veuillez accepter nos conditions d'utilisation pour continuer
              </p>
              {userEmail && (
                <p className="text-center text-muted-foreground mb-4">
                  Adresse e-mail : {userEmail}
                </p>
              )}
              <div className="space-y-4">
                <div className="flex items-start">
                  <Checkbox
                    id="termsAccepted"
                    checked={termsAccepted}
                    onChange={(e) => setTermsAccepted(e.target.checked)}
                    required
                  />
                  <Label htmlFor="termsAccepted" className="ml-2 text-sm">
                    J'accepte les{" "}
                    <span
                      className="underline cursor-pointer"
                      onClick={() =>
                        openLink(
                          "https://fidelipassapp.com/app-terms-of-services"
                        )
                      }
                    >
                      Conditions d'utilisation
                    </span>{" "}
                    et la{" "}
                    <span
                      className="underline cursor-pointer"
                      onClick={() =>
                        openLink("https://fidelipassapp.com/app-privacy-policy")
                      }
                    >
                      Politique de confidentialité
                    </span>
                    *
                  </Label>
                </div>
                <div className="flex items-start">
                  <Checkbox
                    id="legalConsent"
                    checked={legalConsent}
                    onChange={(e) => setLegalConsent(e.target.checked)}
                  />
                  <Label htmlFor="legalConsent" className="ml-2 text-sm">
                    J'accepte de recevoir des e-mails concernant les mises à
                    jour légales et les changements de politique
                  </Label>
                </div>
                <div className="flex items-start">
                  <Checkbox
                    id="marketingConsent"
                    checked={marketingConsent}
                    onChange={(e) => setMarketingConsent(e.target.checked)}
                  />
                  <Label htmlFor="marketingConsent" className="ml-2 text-sm">
                    J'accepte de recevoir des e-mails marketing et promotionnels
                  </Label>
                </div>
                <p className="text-xs text-left text-gray-500">*Obligatoire</p>
              </div>
              <Button
                onClick={() => {
                  if (termsAccepted) {
                    setStep(1);
                  } else {
                    alert(
                      "Vous devez accepter les termes d'utilisation pour continuer."
                    );
                  }
                }}
                className="w-full h-12 text-lg font-semibold"
              >
                J'accepte
              </Button>
              <Button
                onClick={handleDeleteAccount}
                variant="outline"
                className="w-full h-12 text-lg font-semibold"
              >
                Supprimer mon compte
              </Button>
            </CardContent>
          </Card>
        ) : userType === null ? (
          <Card className="w-full max-w-md shadow-sm">
            <CardHeader>
              <CardTitle className="text-2xl font-bold text-center">
                Bienvenue sur FideliPass
              </CardTitle>
            </CardHeader>
            <CardContent className="space-y-6">
              <p className="text-center text-muted-foreground mb-4">
                Êtes-vous un client ou un commerçant ?
              </p>
              <div className="space-y-4">
                <Button
                  onClick={() => setUserType("client")}
                  className="w-full h-12 text-lg font-semibold flex items-center justify-center"
                >
                  <User className="mr-2 h-5 w-5" /> Client
                </Button>
                <Button
                  onClick={() => setUserType("merchant")}
                  className="w-full h-12 text-lg font-semibold flex items-center justify-center"
                >
                  <Store className="mr-2 h-5 w-5" /> Commerçant
                </Button>
              </div>
            </CardContent>
          </Card>
        ) : (
          <div className="flex flex-col h-full">
            <Card className="w-full shadow-sm flex-grow flex flex-col">
              <CardHeader className="text-center">
                <CardTitle className="text-2xl font-bold">
                  {steps[step - 1]?.title || ""}
                </CardTitle>
              </CardHeader>
              <CardContent className="space-y-6 flex-grow flex flex-col justify-between">
                <div>
                  <div className="flex justify-center mb-6">
                    {userType === "client"
                      ? [
                          <CreditCard key="credit-card" size={48} />,
                          <QrCode key="qr-code" size={48} />,
                          <Gift key="gift" size={48} />,
                          <Earth key="earth" size={48} />,
                        ][step - 1] || null
                      : [
                          <Store key="store" size={48} />,
                          <CreditCard key="credit-card" size={48} />,
                          <QrCode key="qr-code" size={48} />,
                          <BarChart2 key="bar-chart" size={48} />,
                          <Earth key="earth" size={48} />,
                        ][step - 1] || null}
                  </div>
                  <p className="text-center text-muted-foreground">
                    {steps[step - 1]?.content || ""}
                  </p>
                </div>
                <div>
                  <div className="flex justify-between items-center mb-4">
                    {steps.map((_, index) => (
                      <div
                        key={index}
                        className={`h-1 flex-1 ${
                          index <= step - 1 ? "bg-primary" : "bg-muted"
                        } ${index !== steps.length - 1 ? "mr-1" : ""}`}
                      />
                    ))}
                  </div>
                  <Button
                    onClick={handleNext}
                    className="w-full h-12 text-lg font-semibold"
                  >
                    {step < steps.length ? "Suivant" : "Commencer"}
                  </Button>
                </div>
              </CardContent>
            </Card>
            <div
              className="flex items-center justify-center text-sm text-gray-500 cursor-pointer hover:text-gray-700 transition-colors duration-200 mt-4"
              onClick={handleFinishOnboarding}
            >
              Passer <ChevronRight className="ml-1 h-4 w-4" />
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default Onboarding;
