import React, { useState, useEffect } from "react";
import { Button } from "../@/components/ui/button";
import {
  UserCircle,
  MessageCircle,
  Store,
  BarChart2,
  CreditCard,
  InfoIcon,
  RefreshCcw,
} from "lucide-react";
import { useNavigate } from "react-router-dom";
import {
  Card,
  CardContent,
  CardHeader,
  CardTitle,
} from "../@/components/ui/card";
import { auth, db } from "../firebase";
import { collection, query, where, getDocs } from "firebase/firestore";
import { Capacitor } from "@capacitor/core";
import { ReactComponent as FideliPassLogo } from "../assets/FideliPass nom.svg";
import useRevenueCatEntitlement from "../hooks/useRevenueCatEntitlement";
import { Purchases } from "@revenuecat/purchases-capacitor";

const SettingsPage: React.FC = () => {
  const navigate = useNavigate();
  const [hasMerchantProfile, setHasMerchantProfile] = useState(false);
  const isNative = Capacitor.isNativePlatform();
  const { hasActiveEntitlement, entitlements } = useRevenueCatEntitlement();

  /*
  useEffect(() => {
    console.log("Active entitlement: " + hasActiveEntitlement);
  }, [hasActiveEntitlement]);*/

  useEffect(() => {
    const checkMerchantProfile = async () => {
      const user = auth.currentUser;
      if (user) {
        const q = query(
          collection(db, "merchants"),
          where("userId", "==", user.uid)
        );
        const querySnapshot = await getDocs(q);
        setHasMerchantProfile(!querySnapshot.empty);
      }
    };

    checkMerchantProfile();
  }, []);

  const handleAccountClick = () => {
    navigate("/account");
  };

  const handleRestorePurchases = async () => {
    try {
      const customerInfo = await Purchases.restorePurchases();
      //console.log("Achats restaurés avec succès :", customerInfo);
      alert("Vos achats ont été restaurés avec succès.");
    } catch (error) {
      console.error("Erreur lors de la restauration des achats :", error);
      alert(
        "Une erreur est survenue lors de la restauration de vos achats. Veuillez réessayer."
      );
    }
  };

  return (
    <div
      className={`fixed inset-0 flex flex-col bg-background ${
        isNative
          ? "safe-area-inset-top safe-area-inset-left safe-area-inset-right"
          : ""
      }`}
    >
      <div className="bg-white z-10 p-4">
        <div className="flex justify-between items-center">
          <FideliPassLogo className="h-5 w-auto" />
          <div className="w-12 h-12"></div>
        </div>
      </div>
      <div className="flex-grow overflow-y-auto">
        <div className="p-4">
            <Card className="mb-4">
              <CardHeader>
                <CardTitle>Paramètres</CardTitle>
              </CardHeader>
              <CardContent>
                <Button
                  className="w-full mb-4 justify-start text-lg h-12 py-0"
                  variant="ghost"
                  onClick={handleAccountClick}
                >
                  <UserCircle className="mr-2 h-6 w-6" /> Compte
                </Button>

                <a href="mailto:team@fidelipassapp.com">
                  <Button
                    className="w-full mb-4 justify-start text-lg h-12 py-0"
                    variant="ghost"
                  >
                    <MessageCircle className="mr-2 h-6 w-6" /> Contactez-nous
                  </Button>
                </a>

                <Button
                  className="w-full justify-start text-lg mb-4 h-12 py-0"
                  variant="ghost"
                  onClick={() =>
                    navigate(
                      hasActiveEntitlement
                        ? "/merchant-profiles"
                        : "/change-subscription"
                    )
                  }
                >
                  <Store className="mr-2 h-6 w-6" />
                  {hasActiveEntitlement || hasMerchantProfile
                    ? "Mon commerce"
                    : "Créer mon commerce"}
                </Button>

                {hasActiveEntitlement && (
                  <Button
                    className="w-full mb-4 justify-start text-lg h-12 py-0"
                    variant="ghost"
                    onClick={() => navigate("/manage-subscription")}
                  >
                    <CreditCard className="mr-2 h-6 w-6" />
                    Gérer mon abonnement
                  </Button>
                )}

                {!hasActiveEntitlement && hasMerchantProfile && (
                  <Button
                    className="w-full mb-4 justify-start text-lg h-12 py-0"
                    variant="ghost"
                    onClick={() => navigate("/change-subscription")}
                  >
                    <CreditCard className="mr-2 h-6 w-6" />
                    Renouveler mon abonnement
                  </Button>
                )}

                {isNative && (
                  <Button
                    className="w-full mb-4 justify-start text-lg h-12 py-0"
                    variant="ghost"
                    onClick={handleRestorePurchases}
                  >
                    <RefreshCcw className="mr-2 h-6 w-6" />
                    Restaurer les achats
                  </Button>
                )}

                {hasMerchantProfile && (
                  <Button
                    className="w-full mb-4 justify-start text-lg h-12 py-0"
                    variant="ghost"
                    onClick={() => navigate("/analytics")}
                  >
                    <BarChart2 className="mr-2 h-6 w-6" /> Analyses
                  </Button>
                )}
                <Button
                  className="w-full mb-4 justify-start text-lg h-12 py-0"
                  variant="ghost"
                  onClick={() => navigate("/about")}
                >
                  <InfoIcon className="mr-2 h-6 w-6" /> À propos
                </Button>
              </CardContent>
            </Card>
        </div>
      </div>
    </div>
  );
};

export default SettingsPage;
