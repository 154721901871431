import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { app, auth, db } from "../firebase";
import { doc, updateDoc, getDoc } from "firebase/firestore";
import { sendEmailVerification, sendPasswordResetEmail } from "firebase/auth";
import { Button } from "../@/components/ui/button";
import { Input } from "../@/components/ui/input";
import {
  Card,
  CardHeader,
  CardTitle,
  CardContent,
} from "../@/components/ui/card";
import { LogOut, Trash2, ChevronLeft, Mail, Check } from "lucide-react";
import { Capacitor } from "@capacitor/core";
import { Browser } from "@capacitor/browser";
import { FaApple, FaGoogle } from "react-icons/fa";
import {
  AlertDialog,
  AlertDialogAction,
  AlertDialogCancel,
  AlertDialogContent,
  AlertDialogDescription,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogTitle,
} from "../@/components/ui/alert-dialog";
import { Dialog } from "@capacitor/dialog";
import useRevenueCatEntitlement from "../hooks/useRevenueCatEntitlement";
import { getAuth, fetchSignInMethodsForEmail } from "firebase/auth";
import { getCountryCode, countryMap } from "../utils/countryUtils";
import { Purchases } from "@revenuecat/purchases-capacitor";
import { Purchases as PurchasesWeb } from "@revenuecat/purchases-js";
import { getFunctions, httpsCallable } from "firebase/functions";

interface LinkedAccount {
  providerId: string;
  displayName: string | null;
  email: string | null;
}

interface DeleteAccountResult {
  success: boolean;
  message: string;
}

const AccountPage: React.FC<{ onExitComplete: () => void }> = ({
  onExitComplete,
}) => {
  const [displayName, setDisplayName] = useState("");
  const [email, setEmail] = useState("");
  const navigate = useNavigate();
  const isNative = Capacitor.isNativePlatform();
  const { hasActiveEntitlement, subscriptionStatus } =
    useRevenueCatEntitlement();
  const [userCountry, setUserCountry] = useState<string>("");
  const [isExiting, setIsExiting] = useState(false);
  const [linkedAccounts, setLinkedAccounts] = useState<LinkedAccount[]>([]);
  const [isEmailVerified, setIsEmailVerified] = useState(false);

  // Etats pour les dialogues web
  const [showWebSubscriptionWarning, setShowWebSubscriptionWarning] =
    useState(false);
  const [
    showWebExternalSubscriptionWarning,
    setShowWebExternalSubscriptionWarning,
  ] = useState(false);
  const [showWebDeleteConfirmation, setShowWebDeleteConfirmation] =
    useState(false);
  const [showWebLogoutConfirmation, setShowWebLogoutConfirmation] =
    useState(false);

  useEffect(() => {
    if (isNative) {
      document.body.style.overflow = "hidden";
      return () => {
        document.body.style.overflow = "";
      };
    }
  }, [isNative]);

  const handleResetPassword = async () => {
    const user = auth.currentUser;
    if (user && user.email) {
      try {
        await sendPasswordResetEmail(auth, user.email);
        if (isNative) {
          await Dialog.alert({
            title: "E-mail envoyé",
            message:
              "Un e-mail pour gérer votre mot de passe a été envoyé à votre adresse e-mail.",
            buttonTitle: "OK",
          });
        } else {
          alert(
            "Un e-mail pour gérer votre mot de passe a été envoyé à votre adresse e-mail."
          );
        }
      } catch (error) {
        console.error(
          "Erreur lors de l'envoi de l'e-mail de gestion du mot de passe :",
          error
        );
        if (isNative) {
          await Dialog.alert({
            title: "Erreur",
            message:
              "Une erreur est survenue lors de l'envoi de l'e-mail de gestion du mot de passe.",
            buttonTitle: "OK",
          });
        } else {
          alert(
            "Une erreur est survenue lors de l'envoi de l'e-mail de gestion du mot de passe."
          );
        }
      }
    }
  };

  useEffect(() => {
    const user = auth.currentUser;
    if (user) {
      setDisplayName(user.displayName || "");
      setEmail(user.email || "");
    }
  }, []);

  const handleCountryChange = async (
    event: React.ChangeEvent<HTMLSelectElement>
  ) => {
    const selectedCountry = event.target.value;
    const countryCode = getCountryCode(selectedCountry);
    setUserCountry(countryCode);

    const user = auth.currentUser;
    if (user) {
      await updateDoc(doc(db, "users", user.uid), { countryId: countryCode });

      // Effacer le cache des magasins
      localStorage.removeItem("globalShops");
      localStorage.removeItem("globalShopsTimestamp");

      // Indiquer qu'un rechargement forcé est nécessaire
      localStorage.setItem("forceReload", "true");

      // Recharger l'application
      if (Capacitor.isNativePlatform()) {
        // Pour les applications natives
        window.location.reload();
      } else {
        // Pour le web
        window.location.href = "/";
      }
    }
  };

  useEffect(() => {
    const fetchUserData = async () => {
      const user = auth.currentUser;
      if (user) {
        setDisplayName(user.displayName || "");
        setEmail(user.email || "");
        setIsEmailVerified(user.emailVerified);

        const userDoc = await getDoc(doc(db, "users", user.uid));
        if (userDoc.exists()) {
          const userData = userDoc.data();
          setUserCountry(userData.countryId || "");
        }
      }
    };

    fetchUserData();
  }, []);

  const handleLogout = async () => {
    if (isNative) {
      const { value } = await Dialog.confirm({
        title: "Déconnexion",
        message: "Vous allez être déconnecté, voulez-vous continuer ?",
        okButtonTitle: "Oui",
        cancelButtonTitle: "Non",
      });
      if (value) {
        try {
          await auth.signOut();
          navigate("/login");
        } catch (error) {
          console.error("Erreur lors de la déconnexion:", error);
        }
      }
    } else {
      setShowWebLogoutConfirmation(true);
    }
  };

  const confirmWebLogout = async () => {
    try {
      await auth.signOut();
      navigate("/login");
    } catch (error) {
      console.error("Erreur lors de la déconnexion:", error);
    }
  };

  const handleDeleteAccount = async () => {
    const user = auth.currentUser;
    if (user) {
      try {
        if (
          subscriptionStatus &&
          subscriptionStatus.isActive &&
          subscriptionStatus.willRenew
        ) {
          isNative
            ? renderDialog("subscriptionWarning")
            : setShowWebSubscriptionWarning(true);
          return;
        }

        isNative
          ? renderDialog("deleteConfirmation")
          : setShowWebDeleteConfirmation(true);
      } catch (error) {
        console.error("Erreur lors de la vérification de l'abonnement:", error);
        alert(
          "Une erreur est survenue lors de la vérification de l'abonnement. Veuillez réessayer."
        );
      }
    }
  };

  const confirmDeleteAccount = async () => {
    const user = auth.currentUser;
    if (user) {
      try {
        const functions = getFunctions(app, "europe-west6");
        const deleteUserAccountFunction = httpsCallable<
          { userCountry: string },
          DeleteAccountResult
        >(functions, "deleteUserAccount");

        const result = await deleteUserAccountFunction({
          userCountry: userCountry,
        });

        if (result.data.success) {
          // Nettoyage local
          localStorage.clear();

          // Navigation vers la page de connexion
          window.location.reload();
          navigate("/login");
        } else {
          throw new Error(result.data.message);
        }
      } catch (error) {
        console.error("Erreur lors de la suppression du compte:", error);
        if (error instanceof Error) {
          alert(
            `Une erreur est survenue lors de la suppression du compte : ${error.message}`
          );
        } else {
          alert(
            "Une erreur inconnue est survenue lors de la suppression du compte. Veuillez réessayer."
          );
        }
      }
    }
  };

  const openSubscriptionManagement = async () => {
    const user = auth.currentUser;
    if (user) {
      try {
        if (!hasActiveEntitlement) {
          navigate("/payment");
        } else {
          let managementURL;
          if (isNative) {
            const { customerInfo } = await Purchases.getCustomerInfo();
            managementURL = customerInfo.managementURL;
          } else {
            const customerInfo =
              await PurchasesWeb.getSharedInstance().getCustomerInfo();
            managementURL = customerInfo.managementURL;
          }

          // For testing purposes
          /*const stripeLoginUrl =
            "https://billing.stripe.com/p/login/test_bIYbJpcxefme6CAfYY";*/
          const stripeLoginUrl =
            "https://billing.stripe.com/p/login/7sI037ezwb763o47ss";

          if (managementURL) {
            // L'abonnement a été effectué via RevenueCat
            if (isNative) {
              await Browser.open({ url: managementURL });
            } else {
              window.open(managementURL, "_blank");
            }
          } else {
            // L'abonnement a été effectué via Stripe
            if (isNative) {
              renderDialog("externalSubscriptionWarning");
            } else {
              window.open(stripeLoginUrl, "_blank");
            }
          }
        }
      } catch (error) {
        console.error("Erreur lors de la gestion de l'abonnement:", error);
        if (isNative) {
          await Dialog.alert({
            title: "Erreur",
            message:
              "Une erreur est survenue lors de la tentative de gestion de l'abonnement.",
            buttonTitle: "OK",
          });
        } else {
          alert(
            "Une erreur est survenue lors de la tentative de gestion de l'abonnement."
          );
        }
      }
    }
  };

  const handleBackClick = () => {
    setIsExiting(true);
    setTimeout(() => {
      onExitComplete();
    }, 300); // 300ms pour la durée de l'animation
  };

  const renderDialog = async (type: string) => {
    if (isNative) {
      switch (type) {
        case "subscriptionWarning":
          await Dialog.alert({
            title: "Abonnement actif",
            message:
              "Vous ne pouvez pas supprimer votre compte tant que votre abonnement est actif. Veuillez d'abord annuler votre abonnement.",
            buttonTitle: "Fermer",
          });
          const { value } = await Dialog.confirm({
            title: "Gérer l'abonnement",
            message: "Voulez-vous gérer votre abonnement maintenant ?",
            okButtonTitle: "Oui",
            cancelButtonTitle: "Non",
          });
          if (value) {
            openSubscriptionManagement();
          }
          break;
        case "externalSubscriptionWarning":
          await Dialog.alert({
            title: "Gérer l'abonnement",
            message:
              "Cet abonnement ne peut pas être annulé ou modifié dans cette application car il a été acheté sur une autre plateforme. Pour gérer votre abonnement, veuillez vous connecter à votre compte où vous avez effectué l'achat.",
            buttonTitle: "Compris",
          });
          break;
        case "deleteConfirmation":
          const { value: confirmDelete } = await Dialog.confirm({
            title: "Êtes-vous sûr de vouloir supprimer votre compte ?",
            message:
              "Cette action est irréversible. Voici les conséquences de la suppression de votre compte :\n" +
              "- Toutes vos données personnelles seront supprimées\n" +
              "- Vous perdrez l'accès à tous vos commerces et cartes de fidélité\n" +
              "- Vous ne pourrez plus accéder à l'application avec ce compte\n" +
              "- Tous les services tiers associés (Google, Apple, etc.) seront automatiquement déconnectés",
            okButtonTitle: "Confirmer la suppression",
            cancelButtonTitle: "Annuler",
          });
          if (confirmDelete) {
            confirmDeleteAccount();
          }
          break;
      }
    }
  };

  useEffect(() => {
    const auth = getAuth();
    const user = auth.currentUser;

    if (user) {
      // Get linked accounts
      const accounts: LinkedAccount[] = user.providerData.map((provider) => ({
        providerId: provider.providerId,
        displayName: provider.displayName,
        email: provider.email,
      }));
      setLinkedAccounts(accounts);
    }
  }, []);

  const resendVerificationEmail = async () => {
    const user = auth.currentUser;
    if (user && !user.emailVerified) {
      try {
        await sendEmailVerification(user);
        if (isNative) {
          await Dialog.alert({
            title: "E-mail envoyé",
            message:
              "Un nouvel e-mail de vérification a été envoyé à votre adresse e-mail.",
            buttonTitle: "OK",
          });
        } else {
          alert(
            "Un nouvel e-mail de vérification a été envoyé à votre adresse e-mail."
          );
        }
      } catch (error) {
        console.error(
          "Erreur lors de l'envoi de l'e-mail de vérification:",
          error
        );
        if (isNative) {
          await Dialog.alert({
            title: "Erreur",
            message:
              "Une erreur est survenue lors de l'envoi de l'e-mail de vérification.",
            buttonTitle: "OK",
          });
        } else {
          alert(
            "Une erreur est survenue lors de l'envoi de l'e-mail de vérification."
          );
        }
      }
    } else if (user && user.emailVerified) {
      if (isNative) {
        await Dialog.alert({
          title: "Déjà vérifié",
          message: "Votre e-mail est déjà vérifié.",
          buttonTitle: "OK",
        });
      } else {
        alert("Votre e-mail est déjà vérifié.");
      }
      setIsEmailVerified(true);
    }
  };

  return (
    <div
      className={`fixed inset-0 flex flex-col bg-background ${
        isExiting ? "slide-out-right" : "slide-in-right"
      } ${
        isNative
          ? "safe-area-inset-top safe-area-inset-left safe-area-inset-right"
          : ""
      }`}
    >
      <div className="bg-white z-10 p-4">
        <div className="flex justify-between items-center">
          <Button
            variant="ghost"
            size="icon"
            className="rounded-full w-12 h-12"
            onClick={handleBackClick}
          >
            <ChevronLeft className="h-6 w-6" />
          </Button>
          <div className="w-12 h-12"></div>{" "}
          {/* Espace vide pour maintenir l'alignement */}
        </div>
      </div>
      <div className="flex-grow overflow-y-auto">
        <div className="p-4">
          <Card>
            <CardHeader>
              <CardTitle>Mon compte</CardTitle>
            </CardHeader>
            <CardContent>
              <div>
                <label
                  htmlFor="email"
                  className="block text-sm font-medium text-gray-700"
                >
                  Email
                </label>
                <Input
                  id="email"
                  type="email"
                  value={email}
                  disabled
                  className="mt-1 bg-gray-100 h-12"
                />
              </div>
              <div className="mt-4">
                <label
                  htmlFor="country"
                  className="block text-sm font-medium text-gray-700"
                >
                  Pays
                </label>
                <select
                  id="country"
                  value={
                    Object.keys(countryMap).find(
                      (key) => countryMap[key] === userCountry
                    ) || ""
                  }
                  onChange={handleCountryChange}
                  className="mt-1 block w-full pl-3 pr-10 py-2 text-base bg-gray-100 border border-gray-200 focus:outline-none focus:ring-primary focus:border-primary sm:text-sm rounded-md h-12"
                >
                  <option value="">Sélectionnez un pays</option>
                  {Object.keys(countryMap).map((country) => (
                    <option key={country} value={country}>
                      {country}
                    </option>
                  ))}
                </select>
              </div>

              <div className="mt-6">
                <label
                  htmlFor="accounts"
                  className="block text-sm font-medium text-gray-700 mb-2"
                >
                  Comptes liés
                </label>
                {linkedAccounts.map((account, index) => (
                  <li
                    key={index}
                    className="flex items-center justify-between h-12 p-2 bg-gray-100 border border-gray-200 rounded-md mb-2"
                  >
                    <div className="flex items-center">
                      {account.providerId === "password" && (
                        <Mail className="h-5 w-5 text-gray-500 mr-3" />
                      )}
                      {account.providerId === "google.com" && (
                        <FaGoogle className="h-5 w-5 text-gray-800 mr-3" />
                      )}
                      {account.providerId === "apple.com" && (
                        <FaApple className="h-5 w-5 text-gray-800 mr-3" />
                      )}
                      <span className="text-sm">
                        {account.providerId === "password"
                          ? "Email"
                          : account.providerId === "google.com"
                          ? "Google"
                          : account.providerId === "apple.com"
                          ? "Apple"
                          : account.providerId}
                      </span>
                      <span className="ml-2 text-xs text-gray-500">
                        {account.email || account.displayName}
                      </span>
                    </div>
                    {account.providerId === "password" ? (
                      isEmailVerified ? (
                        <Check className="h-5 w-5 text-gray-500" />
                      ) : (
                        <Button
                          variant="link"
                          size="sm"
                          onClick={() => {
                            if (isNative) {
                              Dialog.confirm({
                                title: "Vérification de l'e-mail",
                                message:
                                  "Voulez-vous renvoyer votre e-mail de vérification ?",
                                okButtonTitle: "Oui",
                                cancelButtonTitle: "Non",
                              }).then(({ value }) => {
                                if (value) {
                                  resendVerificationEmail();
                                }
                              });
                            } else {
                              resendVerificationEmail();
                            }
                          }}
                          className="text-xs text-blue-500"
                        >
                          Vérifier
                        </Button>
                      )
                    ) : (
                      <Check className="h-5 w-5 text-gray-500" />
                    )}
                  </li>
                ))}
              </div>

              <Button
                onClick={handleResetPassword}
                className="w-full mt-6 h-12"
              >
                Gérer le mot de passe
              </Button>
            </CardContent>
            <CardContent>
              <div className="space-y-4">
                <Button
                  className="w-full center text-sm text-red-500 h-12"
                  variant="ghost"
                  onClick={handleLogout}
                >
                  <LogOut className="mr-2 h-6 w-6" /> Se déconnecter
                </Button>
                <Button
                  className="w-full center text-sm text-red-500 h-12"
                  variant="ghost"
                  onClick={handleDeleteAccount}
                >
                  <Trash2 className="mr-2 h-6 w-6" /> Supprimer mon compte
                </Button>
              </div>
            </CardContent>
          </Card>
        </div>
      </div>
      {!isNative && (
        <>
          <AlertDialog
            open={showWebSubscriptionWarning}
            onOpenChange={setShowWebSubscriptionWarning}
          >
            <AlertDialogContent className="fixed inset-0 z-50 flex items-center justify-center bg-black/50">
              <div className="max-w-md w-full bg-background p-6 rounded-lg shadow-lg">
                <AlertDialogHeader className="space-y-2 text-center">
                  <AlertDialogTitle className="text-xl font-semibold">
                    Abonnement actif
                  </AlertDialogTitle>
                  <AlertDialogDescription>
                    Vous ne pouvez pas supprimer votre compte tant que votre
                    abonnement est actif. Veuillez d'abord annuler votre
                    abonnement.
                  </AlertDialogDescription>
                </AlertDialogHeader>
                <AlertDialogFooter className="mt-6 flex justify-end space-x-2">
                  <AlertDialogCancel
                    onClick={() => setShowWebSubscriptionWarning(false)}
                  >
                    Fermer
                  </AlertDialogCancel>
                  <AlertDialogAction onClick={openSubscriptionManagement}>
                    Gérer l'abonnement
                  </AlertDialogAction>
                </AlertDialogFooter>
              </div>
            </AlertDialogContent>
          </AlertDialog>
          <AlertDialog
            open={showWebExternalSubscriptionWarning}
            onOpenChange={setShowWebExternalSubscriptionWarning}
          >
            <AlertDialogContent className="fixed inset-0 z-50 flex items-center justify-center bg-black/50">
              <div className="max-w-md w-full bg-background p-6 rounded-lg shadow-lg">
                <AlertDialogHeader className="space-y-2 text-center">
                  <AlertDialogTitle className="text-xl font-semibold">
                    Gérer l'abonnement
                  </AlertDialogTitle>
                  <AlertDialogDescription>
                    Cet abonnement ne peut pas être annulé ou modifié dans cette
                    application car il a été acheté sur une autre plateforme.
                    Pour gérer votre abonnement, veuillez vous connecter à votre
                    compte où vous avez effectué l'achat.
                  </AlertDialogDescription>
                </AlertDialogHeader>
                <AlertDialogFooter className="mt-6 flex justify-end space-x-2">
                  <AlertDialogAction
                    onClick={() => setShowWebExternalSubscriptionWarning(false)}
                  >
                    Compris
                  </AlertDialogAction>
                </AlertDialogFooter>
              </div>
            </AlertDialogContent>
          </AlertDialog>
          <AlertDialog
            open={showWebDeleteConfirmation}
            onOpenChange={setShowWebDeleteConfirmation}
          >
            <AlertDialogContent className="fixed inset-0 z-50 flex items-center justify-center bg-black/50">
              <div className="max-w-md w-full bg-background p-6 rounded-lg shadow-lg">
                <AlertDialogHeader className="space-y-2 text-center">
                  <AlertDialogTitle className="text-xl font-semibold">
                    Êtes-vous sûr de vouloir supprimer votre compte ?
                  </AlertDialogTitle>
                  <AlertDialogDescription className="text-sm text-muted-foreground">
                    Cette action est irréversible. Voici les conséquences de la
                    suppression de votre compte :
                    <ul className="list-disc list-inside mt-2 text-left">
                      <li>Toutes vos données personnelles seront supprimées</li>
                      <li>
                        Vous perdrez l'accès à tous vos commerces et cartes de
                        fidélité
                      </li>
                      <li>
                        Vous ne pourrez plus accéder à l'application avec ce
                        compte
                      </li>
                    </ul>
                  </AlertDialogDescription>
                </AlertDialogHeader>
                <AlertDialogFooter className="mt-6 flex justify-end space-x-2">
                  <AlertDialogCancel
                    onClick={() => setShowWebDeleteConfirmation(false)}
                  >
                    Annuler
                  </AlertDialogCancel>
                  <AlertDialogAction
                    onClick={confirmDeleteAccount}
                    className="bg-red-600 text-white hover:bg-red-700"
                  >
                    Confirmer la suppression
                  </AlertDialogAction>
                </AlertDialogFooter>
              </div>
            </AlertDialogContent>
          </AlertDialog>
          <AlertDialog
            open={showWebLogoutConfirmation}
            onOpenChange={setShowWebLogoutConfirmation}
          >
            <AlertDialogContent className="fixed inset-0 z-50 flex items-center justify-center bg-black/50">
              <div className="max-w-md w-full bg-background p-6 rounded-lg shadow-lg">
                <AlertDialogHeader className="space-y-2 text-center">
                  <AlertDialogTitle className="text-xl font-semibold">
                    Déconnexion
                  </AlertDialogTitle>
                  <AlertDialogDescription>
                    Vous allez être déconnecté, voulez-vous continuer ?
                  </AlertDialogDescription>
                </AlertDialogHeader>
                <AlertDialogFooter className="mt-6 flex justify-end space-x-2">
                  <AlertDialogCancel
                    onClick={() => setShowWebLogoutConfirmation(false)}
                  >
                    Non
                  </AlertDialogCancel>
                  <AlertDialogAction onClick={confirmWebLogout}>
                    Oui
                  </AlertDialogAction>
                </AlertDialogFooter>
              </div>
            </AlertDialogContent>
          </AlertDialog>
        </>
      )}
    </div>
  );
};

export default AccountPage;
