import React, { useState, useEffect, useCallback } from "react";
import {
  BrowserRouter as Router,
  Route,
  Routes,
  Navigate,
  useLocation,
} from "react-router-dom";
import { useAuthState } from "react-firebase-hooks/auth";
import { auth, db } from "./firebase";
import {
  doc,
  setDoc,
  getDoc,
  updateDoc,
  collection,
  query,
  getDocs,
  persistentLocalCache,
} from "firebase/firestore";
import WalletPage from "./pages/WalletPage";
import ScanPage from "./pages/ScanPage";
import AddShopPage from "./pages/AddShopPage";
import Footer from "./components/Footer";
import SettingsPage from "./pages/SettingsPage";
import LoginPage from "./pages/LoginPage";
import NewMerchantPage from "./pages/NewMerchantPage";
import CameraPage from "./pages/CameraPage";
import MapPage from "./pages/MapPage";
import PaymentPage from "./pages/PaymentPage";
import ChangeSubscriptionPage from "./pages/ChangeSubscriptionPage";
import { Capacitor } from "@capacitor/core";
import Spinner from "./@/components/ui/spinner";
import EditMerchantPage from "./pages/EditMerchantPage";
import { Purchases, LOG_LEVEL } from "@revenuecat/purchases-capacitor";
import { Purchases as PurchasesWeb } from "@revenuecat/purchases-js";
import { nanoid } from "nanoid";
import { User } from "firebase/auth";
import useRevenueCatEntitlement from "./hooks/useRevenueCatEntitlement";
import OnboardingPage from "./pages/Onboarding";
import AccountPageWrapper from "./pages/AccountPageWrapper";
import MerchantProfilesWrapper from "./pages/MerchantProfilesWrapper";
import ManageSubscriptionPageWrapper from "./pages/ManageSubscriptionPageWrapper";
import AnalyticsPageWrapper from "./pages/AnalyticsPageWrapper";
import AboutPageWrapper from "./pages/AboutPageWrapper";
import WalletPageWrapper from "./components/WalletPageWrapper";

export interface Shop {
  latitude: string;
  longitude: string;
  id: string;
  name: string;
  added: boolean;
  progression: number;
  redemptionCount: number;
  address: string;
  postalCode: string;
  city: string;
  canton?: string;
  country: string;
  description?: string;
  website?: string;
  instagram?: string;
  facebook?: string;
  phone?: string;
  showDescription?: boolean;
  showPhone?: boolean;
  showWebsite?: boolean;
  showInstagram?: boolean;
  showFacebook?: boolean;
  bubbleCount: number;
  offre: string;
  category: string;
  userId: string;
}

export interface UserData {
  qrCode: string;
  countryId: string;
  onboardingCompleted: boolean;
}

const SubscriptionCheck: React.FC<{ children: React.ReactNode }> = ({
  children,
}) => {
  const { subscriptionStatus, isLoading } = useRevenueCatEntitlement();

  if (isLoading) {
    return <div>Chargement...</div>; // Ou un composant de chargement
  }

  if (subscriptionStatus && !subscriptionStatus.isActive) {
    return <Navigate to="/change-subscription" />;
  }

  return <>{children}</>;
};

const Layout: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const [user] = useAuthState(auth);
  const isNative = Capacitor.isNativePlatform();
  const location = useLocation();

  const shouldShowFooter = ![
    "/new-merchant",
    "/payment",
    "/map",
    "/change-subscription",
    "/onboarding",
  ].includes(location.pathname);

  return (
    <div
      className={`flex flex-col min-h-screen bg-background text-foreground ${
        isNative ? "native-platform" : ""
      }`}
    >
      <main
        className={`flex-grow ${
          isNative
            ? "safe-area-inset-top safe-area-inset-left safe-area-inset-right"
            : ""
        }`}
      >
        {children}
      </main>
      {user && shouldShowFooter && (
        <Footer className={isNative ? "safe-area-inset-bottom" : ""} />
      )}
    </div>
  );
};

const App: React.FC = () => {
  const [user, authLoading] = useAuthState(auth);
  const [userData, setUserData] = useState<UserData | null>(null);
  const [globalShops, setGlobalShops] = useState<Shop[]>([]);
  const { hasActiveEntitlement, entitlements } = useRevenueCatEntitlement();
  const [revenueCatLoading, setRevenueCatLoading] = useState(true);

  const isLoading = authLoading || revenueCatLoading;

  const [lastFetchTime, setLastFetchTime] = useState<number>(0);
  const [isFetching, setIsFetching] = useState<boolean>(false);
  const REFRESH_INTERVAL = 12 * 60 * 60 * 1000; // 12 heures en millisecondes

  const loadShops = useCallback(
    async (countryId: string | null) => {
      if (!countryId || isFetching) {
        return;
      }

      const now = Date.now();
      if (now - lastFetchTime < REFRESH_INTERVAL && globalShops.length > 0) {
        return; // Les données sont encore fraîches, pas besoin de les récupérer à nouveau
      }

      setIsFetching(true);
      try {
        const shopsRef = collection(db, "globalShops", "countries", countryId);
        const shopsSnapshot = await getDocs(shopsRef);

        const shops = shopsSnapshot.docs.map(
          (doc) =>
            ({
              id: doc.id,
              ...doc.data(),
            } as Shop)
        );

        setGlobalShops(shops);
        setLastFetchTime(now);
      } catch (error) {
        console.error("Erreur lors du chargement des magasins:", error);
        setGlobalShops([]);
      } finally {
        setIsFetching(false);
      }
    },
    [isFetching, lastFetchTime, globalShops]
  );

  useEffect(() => {
    const loadData = async () => {
      if (user) {
        try {
          const currentUserData = await fetchUserData();
          if (currentUserData) {
            setUserData(currentUserData);
            await loadShops(currentUserData.countryId);
          }
        } catch (error) {
          console.error("Erreur lors de la récupération des données:", error);
        }
      } else {
        setUserData(null);
        setGlobalShops([]);
      }
    };

    loadData();
  }, [user]);

  const fetchUserData = async (): Promise<UserData | null> => {
    if (user) {
      const userDoc = await getDoc(doc(db, "users", user.uid));
      if (userDoc.exists()) {
        const userData = userDoc.data() as UserData;
        return userData;
      } else {
        // Créer un nouveau document utilisateur
        const newUserData: UserData = {
          qrCode: user.uid,
          countryId: "",
          onboardingCompleted: false, // Ajouter cette ligne
        };
        await setDoc(doc(db, "users", user.uid), newUserData);
        return newUserData;
      }
    }
    return null;
  };

  // Fonction pour générer ou récupérer l'identifiant RevenueCat
  async function getRevenueCatUserId(user: User) {
    const userDoc = await getDoc(doc(db, "users", user.uid));
    let revenueCatUserId = userDoc.data()?.revenueCatUserId;

    if (!revenueCatUserId) {
      revenueCatUserId = nanoid(); // Génère un ID unique avec nanoid
      await updateDoc(doc(db, "users", user.uid), { revenueCatUserId });
    }

    return revenueCatUserId;
  }

  useEffect(() => {
    const initializeRevenueCat = async () => {
      setRevenueCatLoading(true);
      let apiKey = "";

      if (Capacitor.isNativePlatform()) {
        if (Capacitor.getPlatform() === "ios") {
          apiKey = process.env.REACT_APP_REVENUECAT_API_KEY_IOS || "";
        } else if (Capacitor.getPlatform() === "android") {
          apiKey = process.env.REACT_APP_REVENUECAT_API_KEY_ANDROID || "";
          //console.log("Using API Key for Android:", apiKey);
        }
      } else {
        apiKey = process.env.REACT_APP_REVENUECAT_API_KEY_WEB || "";
      }

      if (!apiKey) {
        console.error(
          "API key not found for platform:",
          Capacitor.getPlatform()
        );
        setRevenueCatLoading(false);
        return;
      }

      try {
        if (Capacitor.isNativePlatform()) {
          Purchases.setLogLevel({ level: LOG_LEVEL.INFO });
          await Purchases.configure({ apiKey });
          //console.log("RevenueCat initialized successfully on", Capacitor.getPlatform());
        }

        const unsubscribe = auth.onAuthStateChanged(async (user) => {
          if (user) {
            try {
              const revenueCatUserId = await getRevenueCatUserId(user);
              if (Capacitor.isNativePlatform()) {
                const { customerInfo } = await Purchases.getCustomerInfo();
                if (customerInfo.originalAppUserId !== revenueCatUserId) {
                  const { customerInfo: updatedInfo } = await Purchases.logIn({
                    appUserID: revenueCatUserId,
                  });
                  //console.log("User logged into RevenueCat:", revenueCatUserId);
                  //console.log("Customer info:", updatedInfo);
                } else {
                  //console.log("User already logged into RevenueCat");
                }
              } else {
                // Initialize PurchasesWeb only if the user is connected
                let appUserId = revenueCatUserId;
                PurchasesWeb.configure(apiKey, appUserId);
                //console.log("RevenueCat initialized on web with user ID:", revenueCatUserId);
              }
            } catch (error) {
              console.error("Error initializing RevenueCat:", error);
            }
          }
          setRevenueCatLoading(false);
        });

        return () => unsubscribe();
      } catch (error) {
        console.error("Error in RevenueCat initialization process:", error);
        setRevenueCatLoading(false);
      }
    };
    initializeRevenueCat();
  }, []);

  const addShop = async (shopId: string) => {
    if (user) {
      const shopToAdd = globalShops.find((shop) => shop.id === shopId);
      if (shopToAdd) {
        const newShop: Shop = {
          ...shopToAdd,
          added: true,
          progression: 0,
          redemptionCount: 0,
          bubbleCount: shopToAdd.bubbleCount || 10,
        };

        const cleanedShop = Object.fromEntries(
          Object.entries(newShop).filter(([_, v]) => v !== undefined)
        ) as Shop;

        try {
          await setDoc(
            doc(db, "wallets", user.uid, "wallet", shopId),
            cleanedShop
          );
          // Ne pas mettre à jour userData ici
        } catch (error) {
          console.error("Erreur lors de l'ajout du magasin : ", error);
        }
      }
    }
  };

  if (isLoading) {
    return <Spinner />;
  }

  return (
    <Router>
      <Layout>
        <Routes>
          <Route
            path="/login"
            element={!user ? <LoginPage /> : <Navigate to="/" />}
          />
          <Route
            path="/"
            element={user ? <WalletPageWrapper /> : <Navigate to="/login" />}
          />
          <Route
            path="/onboarding"
            element={user ? <OnboardingPage /> : <Navigate to="/login" />}
          />
          <Route
            path="/scan"
            element={
              user ? (
                <ScanPage qrCode={userData?.qrCode || ""} />
              ) : (
                <Navigate to="/login" />
              )
            }
          />
          <Route
            path="/add-shop"
            element={
              user ? (
                <AddShopPage onAddShop={addShop} availableShops={globalShops} />
              ) : (
                <Navigate to="/login" />
              )
            }
          />
          <Route
            path="/settings"
            element={user ? <SettingsPage /> : <Navigate to="/login" />}
          />
          <Route path="/account" element={<AccountPageWrapper user={user} />} />
          <Route
            path="/merchant-profiles"
            element={<MerchantProfilesWrapper user={user} />}
          />
          <Route
            path="/new-merchant"
            element={
              user ? (
                hasActiveEntitlement ? (
                  <NewMerchantPage />
                ) : (
                  <Navigate to="/merchant-profiles" replace />
                )
              ) : (
                <Navigate to="/login" replace />
              )
            }
          />
          <Route
            path="/camera"
            element={
              user ? (
                <SubscriptionCheck>
                  <CameraPage />
                </SubscriptionCheck>
              ) : (
                <Navigate to="/login" />
              )
            }
          />
          <Route
            path="/map"
            element={
              user ? <MapPage shops={globalShops} /> : <Navigate to="/login" />
            }
          />
          <Route
            path="/payment"
            element={
              user ? (
                hasActiveEntitlement ? (
                  <Navigate to="/merchant-profiles" />
                ) : (
                  <PaymentPage />
                )
              ) : (
                <Navigate to="/login" />
              )
            }
          />
          <Route
            path="/change-subscription"
            element={
              user ? (
                !hasActiveEntitlement ? (
                  <Navigate to="/payment" />
                ) : (
                  <ChangeSubscriptionPage />
                )
              ) : (
                <Navigate to="/login" />
              )
            }
          />
          <Route
            path="/manage-subscription"
            element={
              user && hasActiveEntitlement ? (
                <ManageSubscriptionPageWrapper user={user} />
              ) : (
                <Navigate to="/merchant-profiles" replace />
              )
            }
          />
          <Route
            path="/analytics"
            element={
              user && hasActiveEntitlement ? (
                <AnalyticsPageWrapper user={user} />
              ) : (
                <Navigate to="/settings" replace />
              )
            }
          />
          <Route
            path="/edit-merchant/:shopId"
            element={
              user ? <EditMerchantPage /> : <Navigate to="/login" replace />
            }
          />
          <Route path="/about" element={<AboutPageWrapper user={user} />} />
        </Routes>
      </Layout>
    </Router>
  );
};

export default App;
